import { api } from "../constants/String";
import { HTTP } from "../plugins/axios";

export function BlogListGet(projectId, enumType) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/articles/project/${projectId}/type/${enumType}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}

export function BlogByGet(projectId, url) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/articles/project/${projectId}/url/${url}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}

export function BlogPost(data) {
  return new Promise((res, rej) =>
    HTTP.post(`${api}/articles`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}
export function BlogPut(id, data) {
  return new Promise((res, rej) =>
    HTTP.put(`${api}/articles/${id}`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}
export function BlogDelete(id) {
  return new Promise((res, rej) =>
    HTTP.delete(`${api}/articles/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}

export function BlogUrlCheck(projectId, url) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/articles/check/project/${projectId}/url/${url}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}
