import React, { useState, useEffect } from 'react';

// Prime Component
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Badge } from 'primereact/badge';
import { TabView, TabPanel } from 'primereact/tabview';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { Dropdown } from 'primereact/dropdown';

//Constants
import { approvalList } from '../../constants/String';

const Seo = ({blogData}) => {
    const [seoSetting, setSeoSetting] = useState({  //Seo Ayarları
        title: "",
        keyword: "",
        description: ""
    });
    const [pageSetting, setPageSetting] = useState({  //Sayfa Ayarları
        indexSearchEngine: true,
        canonicalUrl: ""
    });
    const [facebookSetting, setFacebookSetting] = useState({  //Facebook Ayarları
        title: "",
        description: "",
        image: ""
    });
    const [twitterSetting, setTwitterSetting] = useState({  //Twitter Ayarları
        title: "",
        description: "",
        image: ""
    });

    const [seoPointsShow, setSeoPointsShow] = useState(false) //Seo Puanı Gösterimi İçin
    const [seoTitleLength, setSeoTitleLength] = useState(0);//Başlık kısmının kelime sayısını saklar
    const [seoDescriptionLength, setSeoDescriptionLength] = useState(0);//Açıklama (description) kısmının kelime sayısını saklar
    

    const btnSeoCalculate = () => {
        //Bu kısımda keywords ve description kısmına girilen değerler kontrol edilir ve puan ortaya çıkartılacak
        setSeoPointsShow(true) //Bu kısım seo puanı hesaplanınca elementi görünür yapıyor.
        //Seo Alanlarını doldurup üst komponente gönderiyoruz
        blogData.seoSetting = seoSetting;
        blogData.pageSetting = pageSetting;
        blogData.facebookSetting = facebookSetting;
        blogData.twitterSetting = twitterSetting;
    }
    useEffect(() => {
        //Burada seo ayarlarından gelen verileri alıyoruz
        if(blogData.seoSetting){
            setSeoSetting(blogData.seoSetting);
            setPageSetting(blogData.pageSetting);
            setFacebookSetting(blogData.facebookSetting);
            setTwitterSetting(blogData.twitterSetting);
        }
    }, [blogData])
    return (
            <div className="col-12">
                <div className="card">
                    <h5>SEO Modülü</h5>
                    <TabView className="tabview-header-icon">
                        <TabPanel header="SEO Ayarları" leftIcon="pi pi-cog">
                    
                            <div className='col-12'>
                                <div className="field">
                                    <label htmlFor="username1" className="block">Başlık</label>
                                    <small style={{marginTop:5,marginBottom:5,display:'block'}}>Karakter Sayısı: <b>{seoTitleLength}</b>. En Fazla  60 karakter olmalıdır.</small>
                                    <InputText id="username1" placeholder="Lütfen başlık (title) girişi yapınız." className="block w-100" 
                                        value={seoSetting.title} 
                                        onChange={(e) => 
                                            {setSeoSetting({...seoSetting, title:e.target.value }); 
                                            setSeoTitleLength(e.target.value.length);
                                        }}
                                    />
                                </div>
                                <div className="field">
                                    <label htmlFor="username1" className="block">Anahtar Kelime</label>
                                    <InputText id="username1" placeholder="Lütfen anahtar kelime (keywords) girişi yapınız." className="block w-100" value={seoSetting.keyword} onChange={(e) => setSeoSetting({...seoSetting, keyword:e.target.value })}/>
                                </div>
                                <div className="field">
                                    <label htmlFor="username1" className="block">Açıklama</label>
                                    <small style={{marginTop:5,marginBottom:5,display:'block'}}>Karakter Sayısı: <b>{seoDescriptionLength}</b>. En Fazla  150 karakter olmalıdır.</small>
                                    <InputTextarea className='block w-100' placeholder="Lütfen açıklama (description) girişi yapınız." value={seoSetting.description} onChange={(e) => {setSeoSetting({...seoSetting, description:e.target.value }); setSeoDescriptionLength(e.target.value.length)}} rows={5} cols={30} />
                                </div>
                                <div className="field">
                                    <Button label="Seo Puanı Hesapla" onClick={btnSeoCalculate} className="p-button-success mr-2 mb-2 w-100" />
                                </div>
                                {seoPointsShow && (
                                    <div className="field">
                                        <small>Aşağıda oluşan puan içeriğinizi analiz ederek oluşturulmuştur. En iyi sonuç için 100 puan yapınız.</small>
                                        <ul>
                                            <li>
                                                <Badge value="8" severity="success" className="mr-2"></Badge>
                                                <span>Anahtar Kelime Sayfa Başlığında Mevcut</span>
                                            </li>
                                            <li>
                                                <Badge value="5" severity="warning" className="mr-2"></Badge>
                                                <span>Anahtar Kelime Yoğunluğu ortalama</span>
                                            </li>
                                            <li>
                                                <Badge value="0" severity="danger" className="mr-2"></Badge>
                                                <span>Anahtar Kelime Yoğunluğu az</span>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>

                        </TabPanel>
                        <TabPanel header="Sayfa Ayarları" leftIcon="pi pi-cog">
                            <div className='grid'>
                                <div className='col-12'>
                                    <div className="field">
                                        <label className="block">Arama Motorlarının Bu Sayfayı İndexlemesine Engel Ol</label>
                                        <Dropdown className="w-100" value={pageSetting.indexSearchEngine} options={approvalList} onChange={(e) => setPageSetting({...pageSetting, indexSearchEngine:e.value})} optionLabel="name" placeholder="Lütfen Seçim Yapınız" />
                                    </div>
                                <div className="field">
                                    <label htmlFor="username1" className="block">Canonical URL</label>
                                    <InputText className='block w-100' placeholder="Lütfen Canonical URL girişi yapınız." value={pageSetting.canonicalUrl} onChange={(e) => setPageSetting({...pageSetting, canonicalUrl:e.target.value })} />
                                </div>
                                </div>
                            </div>

                        </TabPanel>
                        <TabPanel header="Sosyal Medya Ayarları" leftIcon="pi pi-cog">
                            <div className='grid'>
                                <div className='col-12 lg:col-6'>
                                    <div className="field">
                                        <label htmlFor="username1" className="block">Facebook Başlık</label>
                                        <InputText id="username1" placeholder="Lütfen başlık (title) girişi yapınız." className="block w-100" value={facebookSetting.title} onChange={(e) => setFacebookSetting({...facebookSetting, title:e.target.value })}/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="username1" className="block">Facebook Açıklama</label>
                                        <InputTextarea className='block w-100' placeholder="Lütfen açıklama (description) girişi yapınız." value={facebookSetting.description} onChange={(e) => setFacebookSetting({...facebookSetting, description:e.target.value })} rows={5} cols={30} />
                                    </div>
                                    <div className='field'>
                                        <Image src='https://via.placeholder.com/350x350' imageClassName='mw-100 w-100' />
                                        <FileUpload chooseLabel="Görsel Yükle" className='w-100' mode="basic" name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" accept="image/*" maxFileSize={1000000}  />
                                    </div>
                                </div>
                                <div className='col-12 lg:col-6'>
                                    <div className="field">
                                        <label htmlFor="username1" className="block">Twitter Başlık</label>
                                        <InputText id="username1" placeholder="Lütfen başlık (title) girişi yapınız." className="block w-100" value={twitterSetting.title} onChange={(e) => setTwitterSetting({...twitterSetting, title:e.target.value })}/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="username1" className="block">Twitter Açıklama</label>
                                        <InputTextarea className='block w-100' placeholder="Lütfen açıklama (description) girişi yapınız." value={twitterSetting.description} onChange={(e) => setTwitterSetting({...twitterSetting, description:e.target.value })} rows={5} cols={30} />
                                    </div>
                                    <div className='field'>
                                        <Image src='https://via.placeholder.com/350x350' imageClassName='mw-100 w-100' />
                                        <FileUpload chooseLabel="Görsel Yükle" className='w-100' mode="basic" name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" accept="image/*" maxFileSize={1000000}  />
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
    );
}


export default Seo
