export const api = "https://panelapi.clrsoft.com.tr/api/v1";
// export const api = "http://api.testwebsitehome.xyz/api/v1";

export const parsanalitikcomApi = "https://api.pars.clrsoft.xyz/api/v1";
export const parsanalitikcomApiV2 = "https://api.pars.clrsoft.xyz/api/v2";

export const momentDateFormat = "DD.MM.yyyy HH:mm";
export const momentTimeFormat = "HH:mm";

export const approvalList = [
  {
    name: "Hayır",
    id: "0",
  },
  {
    name: "Evet",
    id: "1",
  },
];

//Langue Enums
export const langueEnums = [
  {
    id: "tr",
    name: "Türkçe",
  },
  {
    id: "en",
    name: "İngilizce",
  },
];

//Article Global Enums
export const sliderEnum = "slider";
export const CustomerCommentsEnum = "musteriYorumlari";
export const CustomerReferenceEnum = "musteriReferanslari";
export const productEnum = "urunler";
export const BlogEnum = "bloglar";
export const GalleryEnum = "galeriler";
export const SSSEnum = "sikSorulanSorular";

//clrsoft.com Article spacial enums
export const sssCategoryEnum = [
  {
    name: "Anasayfa SSS",
    code: "anasayfaSikSorulanSorular",
  },
  {
    name: "Mobil Anasayfa SSS",
    code: "MobilAnasayfasiSikSorulanSorular",
  },
];
export const blogCategoryEnum = [
  {
    name: "Genel",
    code: "genelBlog",
  },
  {
    name: "SEO Blog",
    code: "seoBlog",
  },
  {
    name: "Hukuk Alanı",
    code: "hukukAlani",
  },
];

//Developer tüm menüleri görebilsin diye rol
export const developerEnum = "developer";

export const blogModuleEnum = "blog_modulu";
export const sssModuleEnum = "sik_sorulan_sorular_modulu";
export const musteriYorumuModuleEnum = "musteri_yorumu_modulu";
export const galeriModuleEnum = "galeri_modulu";
export const musteriReferansModuleEnum = "musteri_referans_modulu";
export const sliderModuleEnum = "slider_modulu";
export const hizmetlerimizModuleEnum = "hizmetlerimiz_modulu";
export const hizmetVerilenSektorlerModuleEnum = "hizmet_verilen_sektorler_modulu";


