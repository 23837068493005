import React from "react";

// Prime Component
// import { Chart } from "primereact/chart";

// Component

// const lineData = {
//   labels: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz"],
//   datasets: [
//     {
//       label: "Web Sayfası Görüntülenme",
//       data: [65, 59, 80, 81, 56, 55, 40],
//       fill: false,
//       backgroundColor: "#2f4860",
//       borderColor: "#2f4860",
//       tension: 0.4,
//     },
//     {
//       label: "Blog Görüntülenme",
//       data: [28, 48, 40, 19, 86, 27, 90],
//       fill: false,
//       backgroundColor: "#00bb7e",
//       borderColor: "#00bb7e",
//       tension: 0.4,
//     },
//   ],
// };

const Dashboard = (props) => {
  // const [lineOptions, setLineOptions] = useState(null);

  // const applyLightTheme = () => {
  //   const lineOptions = {
  //     plugins: {
  //       legend: {
  //         labels: {
  //           color: "#495057",
  //         },
  //       },
  //     },
  //     scales: {
  //       x: {
  //         ticks: {
  //           color: "#495057",
  //         },
  //         grid: {
  //           color: "#ebedef",
  //         },
  //       },
  //       y: {
  //         ticks: {
  //           color: "#495057",
  //         },
  //         grid: {
  //           color: "#ebedef",
  //         },
  //       },
  //     },
  //   };

  //   setLineOptions(lineOptions);
  // };

  // const applyDarkTheme = () => {
  //   const lineOptions = {
  //     plugins: {
  //       legend: {
  //         labels: {
  //           color: "#ebedef",
  //         },
  //       },
  //     },
  //     scales: {
  //       x: {
  //         ticks: {
  //           color: "#ebedef",
  //         },
  //         grid: {
  //           color: "rgba(160, 167, 181, .3)",
  //         },
  //       },
  //       y: {
  //         ticks: {
  //           color: "#ebedef",
  //         },
  //         grid: {
  //           color: "rgba(160, 167, 181, .3)",
  //         },
  //       },
  //     },
  //   };

  //   setLineOptions(lineOptions);
  // };

  // useEffect(() => {
  //   if (props.colorMode === "light") {
  //     applyLightTheme();
  //   } else {
  //     applyDarkTheme();
  //   }
  // }, [props.colorMode]);

  return (
    <div className="grid">
      <div className="col-12 lg:col-6 xl:col-4">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">
                Toplam Blog (Soon)
              </span>
              <div className="text-900 font-medium text-xl">152</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-blue-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-inbox text-cyan-500 text-xl" />
            </div>
          </div>
          {/* <span className="text-green-500 font-medium">24 new </span>
                    <span className="text-500">since last visit</span> */}
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-4">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">
                Toplam Ürün (Soon)
              </span>
              <div className="text-900 font-medium text-xl">2100</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-orange-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-inbox text-cyan-500 text-xl" />
            </div>
          </div>
          {/* <span className="text-green-500 font-medium">%52+ </span>
                    <span className="text-500">since last week</span> */}
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-4">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">
                Toplam Referans (Soon)
              </span>
              <div className="text-900 font-medium text-xl">28441</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-cyan-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-inbox text-cyan-500 text-xl" />
            </div>
          </div>
          {/* <span className="text-green-500 font-medium">520  </span>
                    <span className="text-500">newly registered</span> */}
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-4">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">
                Toplam Müşteri Yorum (Soon)
              </span>
              <div className="text-900 font-medium text-xl">152</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-blue-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-inbox text-cyan-500 text-xl" />
            </div>
          </div>
          {/* <span className="text-green-500 font-medium">24 new </span>
                    <span className="text-500">since last visit</span> */}
        </div>
      </div>
      {/* <div className='col-12 xl:col-6'>
                <div className="card">
                    <h5>Haftalık Rapor (Soon)</h5>
                    <Chart type="line" data={lineData} options={lineOptions} />
                </div>
            </div>
            <div className='col-12 xl:col-6'>
                <div className="card">
                    <h5>Aylık Rapor(Soon)</h5>
                    <Chart type="line" data={lineData} options={lineOptions} />
                </div>
            </div> */}
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode
  );
};

export default React.memo(Dashboard, comparisonFn);
