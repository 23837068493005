import React from 'react';

// Prime Component
import { Button } from 'primereact/button';

//Component
// import FastCalender from '../calendar/FastCalender';
// import FastPageStatus from '../fastPageStatus/FastPageStatus';

const PageSaveElement = ({btnSave}) => {
    // const [displayFastCalender, setDisplayFastCalender] = useState(false);//Hızlı Tarih Modalı Göster Gizle
    // const [displayFastCalenderData, setDisplayFastCalenderData] = useState(false);//Hızlı Tarih Modalı data
    // const [displayFastPageStatus, setDisplayFastPageStatus] = useState(false);//Hızlı sayfa durumu Modalı Göster Gizle
    // const [displayFastPageStatusData, setDisplayFastPageStatusData] = useState(false);//Hızlı sayfa durumu Modalı data
 
    return (
        <div className='card'>
            <h5>Yayımla</h5>
            {/* <div className='col-12'>
                <FastPageStatus 
                    displayFastPageStatus={displayFastPageStatus} 
                    setDisplayFastPageStatus={setDisplayFastPageStatus} 
                    setDisplayFastPageStatusData={setDisplayFastPageStatusData} 
                /> 
                <FastCalender 
                    displayFastCalender={displayFastCalender} 
                    setDisplayFastCalender={setDisplayFastCalender} 
                    setDisplayFastCalenderData={setDisplayFastCalenderData} 
                /> 
                <ul>
                    <li><i className="pi pi-map-marker"></i> Durum: <b>{displayFastPageStatusData ? (displayFastPageStatusData.name):("Yayımla")}</b>
                        <Button label="Düzenle" className="p-button-default" onClick={() => setDisplayFastPageStatus(true)} />
                    </li>
                    <li><i className="pi pi-calendar"></i> Hemen : <b>{displayFastCalenderData ?(displayFastCalenderData):("Yayımla")}</b>
                        <Button label="Düzenle" className="p-button-default" onClick={() => setDisplayFastCalender(true)} />
                    </li>
                    <li><i className="pi pi-calendar"></i> SEO: <b>Uygun Değil</b>
                        <Button label="Düzenle" onClick={btnSave} className="p-button-default" />
                    </li>
                </ul>
            </div> */}
            <div className='col-12'>
                <div className="field">
                    <Button label="Kaydet" onClick={btnSave} className="p-button-success mr-2 w-100" />
                </div>
            </div>
        </div>
    );
}

export default PageSaveElement;
