import React, {useRef} from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';

export const AppTopbar = (props) => {

    const profileMenu = useRef(null);
    const toast = useRef(null);
    const profileItems = [
        {
            label: 'Hesabım',
            items: [
                {
                    label: 'Profil',
                    icon: 'pi pi-user',
                    command: () => {

                    }
                },
                {
                    label: 'Destek',
                    icon: 'pi pi-user',
                    command: () => {
                        
                    }
                },
                {
                    label: 'Çıkış',
                    icon: 'pi pi-upload',
                    command: () => {
                        localStorage.setItem("token","");
                        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Başarılı şekilde çıkış yapıldı', life: 3000 });
                        setTimeout(() => {
                            window.location.reload();
                            window.location.hash = "/giris"
                        }, 1000);
                    }
                }
            ]
        },
    ];

    return (
        <div className="layout-topbar">
            <Toast ref={toast}></Toast>
            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.png' : 'assets/layout/images/logo-white.png'} alt="logo"/>
                {/* <span>SAKAI</span> */}
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-calendar"/>
                            <span>Events</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-cog"/>
                            <span>Settings</span>
                        </button>
                    </li>
                    <li>
                        
                        <Menu model={profileItems} popup ref={profileMenu} id="popup_menu" />
                        <button className="p-link layout-topbar-button" onClick={(event) => profileMenu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup>
                            <i className="pi pi-user"/>
                        </button>
                    </li>
                </ul>
        </div>
    );
}
