import React, { useState, useRef, useEffect } from "react";

/*Prime Component*/
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { ModulesPost } from "../../service/ModulesService";

const Add = () => {
  const pageTitle = "Modül";
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const [data, setData] = useState({
    code: "",
    name: "",
  });

  useEffect(() => {}, []);

  const save = () => {
    setIsLoading(true);
    ModulesPost(data)
      .then((res) => {
        setIsLoading(false);
        setData({
          code: "",
          name: "",
        });
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Yetki Eklendi",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("cevap geldi=>", err.response.data);
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  return (
    <>
      {isLoading ? (
        <ContentLoading />
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />
          <div className="col">
            <div className="card">
              <h5>{pageTitle}</h5>
              <p>
                Aşağıda ki bilgileri doldurarak {pageTitle} ekleyebilirsiniz.
              </p>
              <div className="grid">
                <div className="col-12 md:col-12">
                  <div className="field">
                    <label htmlFor="teamName">{pageTitle} Adı</label>
                    <InputText
                      placeholder="Lütfen Giriş Yapınız"
                      type="text"
                      id="teamName"
                      value={data.name || ""}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="teamName">{pageTitle} Kodu</label>
                    <InputText
                      placeholder="Lütfen Giriş Yapınız"
                      type="text"
                      id="teamName"
                      value={data.code || ""}
                      onChange={(e) =>
                        setData({ ...data, code: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12 md:col-4"></div>
                <div className="col-12 md:col-4"></div>
                <div className="col-12 md:col-4">
                  <Button
                    label="Kaydet"
                    onClick={save}
                    className="p-button-success mr-2 mb-2"
                    disabled={data.code ? false : true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Add, comparisonFn);
