import { api } from "../constants/String";
import { HTTP } from "../plugins/axios";

export function CompaniesListGet() {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/companies`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
        //return alert(error.response.data.Errors[0].Message);
      })
  );
}

export function CompaniesByGet(id) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/companies/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
        //return alert(error.response.data.Errors[0].Message);
      })
  );
}

export function CompaniesPut(id, data) {
  return new Promise((res, rej) =>
    HTTP.put(`${api}/companies/${id}`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
        //return alert(error.response.data.Errors[0].Message);
      })
  );
}
export function CompaniesPost(data) {
  return new Promise((res, rej) =>
    HTTP.post(`${api}/companies`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
        //return alert(error.response.data.Errors[0].Message);
      })
  );
}

export function CompaniesDelete(id) {
  return new Promise((res, rej) =>
    HTTP.delete(`${api}/companies/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
        //return alert(error.response.data.Errors[0].Message);
      })
  );
}
