import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

const ContentLoading = () => {
  return (
    <div style={{ width: "100%" }}>
      <div style={{ textAlign: "center" }}>
        <ProgressSpinner />
      </div>
    </div>
  );
};

export default ContentLoading;
