import { api } from "../constants/String";
import { HTTP } from "../plugins/axios";
import { GalleryEnum } from "../constants/String";

export function GalleryListGet(projectId) {
  return new Promise((res, rej) =>
    HTTP.get(
      `${api}/articles/project/${projectId}/type/${GalleryEnum}`
    )
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}

export function GalleryByGet(projectId, url) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/articles/project/${projectId}/url/${url}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}

export function GalleryPost(data) {
  return new Promise((res, rej) =>
    HTTP.post(`${api}/articles`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}
export function GalleryPut(id, data) {
  return new Promise((res, rej) =>
    HTTP.put(`${api}/articles/${id}`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}
export function GalleryDelete(id) {
  return new Promise((res, rej) =>
    HTTP.delete(`${api}/articles/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}
