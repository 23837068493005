import {HTTP} from '../plugins/axios';
import { api } from '../constants/String';
export function FileUploadPost(data) {
    const formData = new FormData();
    formData.append("file", data);
    return new Promise((res, rej) =>
        HTTP.post(`${api}/files`, formData, {headers: {"Content-Type": "multipart/form-data"}})
            .then(response => {
                return res(response.data);
            })
            .catch((error) => {

                rej(error);
            }),
    );
  }