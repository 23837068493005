import { api } from "../constants/String";
import { HTTP } from "../plugins/axios";

export function ProjectsListGet(jwtTokenCompanyId) {
  return new Promise((res, rej) =>
    //Burayı düzenle
    HTTP.get(`${api}/projects/by-company/${jwtTokenCompanyId}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
        //return alert(error.response.data.Errors[0].Message);
      })
  );
}

export function ProjectsByGet(id) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/projects/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
        //return alert(error.response.data.Errors[0].Message);
      })
  );
}

export function ProjectsDelete(id) {
  return new Promise((res, rej) =>
    HTTP.delete(`${api}/projects/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
        //return alert(error.response.data.Errors[0].Message);
      })
  );
}

export function ProjectsPost(data) {
  return new Promise((res, rej) =>
    HTTP.post(`${api}/projects`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
        //return alert(error.response.data.Errors[0].Message);
      })
  );
}
export function ProjectsPut(id, data) {
  return new Promise((res, rej) =>
    HTTP.put(`${api}/projects/${id}`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
        //return alert(error.response.data.Errors[0].Message);
      })
  );
}
