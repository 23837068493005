/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

/*Prime Component*/
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { ProjectsByGet, ProjectsPut } from "../../service/ProjectsService";
import { CompaniesListGet } from "../../service/CompaniesService";
import { ModulesGetList } from "../../service/ModulesService";
//Yetkilendirme ve Rol
// import { JwtHelper } from '../../plugins/jwtHelper';

const Add = () => {
  const pageTitle = "Proje";
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const location = useLocation();
  const [pageUrl] = useState(location.pathname.split("/")[2]);
  const [data, setData] = useState({
    name: "",
    company: "",
    moduleList: [],
  });
  const [modulesDataList, setModulesDataList] = useState([]); //Modül listesi

  const [companiesListData, setCompaniesListData] = useState([]); //Şirket listesi
  useEffect(() => {
    setIsLoading(false);

    //Şirket Listesi
    CompaniesListGet().then((res) => {
      console.log("Şirket datas geldi", res);
      setIsLoading(false);
      setCompaniesListData(res);
      //Modül Listesi
      ModulesGetList().then((res) => {
        console.log("Modül datas geldi", res);
        setModulesDataList(res);
        ProjectsByGet(pageUrl).then((res) => {
          console.log("Modül datas geldi", res);
          setData(res);
        });
      });
    });
  }, [pageUrl]);

  const save = () => {
    console.log("sendData", data);
    setIsLoading(true);
    ProjectsPut(data._id, data)
      .then((res) => {
        setIsLoading(false);
        setData({
          name: "",
          company: "",
        });
        console.log("cevap geldi=>", res);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem Başarılı.",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("cevap geldi=>", err.response.data);
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />
          <div className="col">
            <div className="card">
              <h5>Yeni {pageTitle}</h5>
              <p>
                Aşağıda ki bilgileri doldurarak yeni {pageTitle} üyesi
                ekleyebilirsiniz.
              </p>
              <div className="grid">
                <div className="col-12 md:col-12">
                  <div className="field">
                    <label htmlFor="name">{pageTitle} Adı</label>
                    <InputText
                      placeholder="Lütfen Giriş Yapınız"
                      type="text"
                      id="name"
                      value={data.name || ""}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="field">
                    <label htmlFor="personnelGroup">{pageTitle} Şirket</label>
                    <Dropdown
                      value={data.company}
                      options={companiesListData}
                      onChange={(e) =>
                        setData({ ...data, company: e.target.value })
                      }
                      optionValue="_id"
                      optionLabel="name"
                      placeholder="Lütfen Seçim Yapınız"
                    />
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="field">
                    <label htmlFor="personnelEMail">{pageTitle} Modül</label>
                    <MultiSelect
                      value={data.moduleList}
                      className="w-full"
                      options={modulesDataList}
                      onChange={(e) =>
                        setData({ ...data, moduleList: e.target.value })
                      }
                      optionLabel="name"
                      optionValue="_id"
                      placeholder="Lütfen Seçim Yapınız"
                      maxSelectedLabels={3}
                    />
                  </div>
                </div>
                <div className="col-12 md:col-4"></div>
                <div className="col-12 md:col-4"></div>
                <div className="col-12 md:col-4">
                  <Button
                    label="Kaydet"
                    onClick={save}
                    className="p-button-success mr-2 mb-2"
                    disabled={data.name ? false : true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Add, comparisonFn);
