import React, { useState, useEffect, useRef } from "react";

//Plugin
import { JwtHelper } from "../../plugins/jwtHelper";

//Prime Component
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";

// Component
import ContentLoading from "../../components/loading/ContentLoading";
import PageSaveElement from "../../components/subComponents/pageComponent/pageSaveElement";
import ImageUpload from "../../components/subComponents/ImageUpload/ImageUpload";

/*Service*/
import { ProjectsListGet } from "../../service/ProjectsService";
import { BlogPost } from "../../service/BlogService";

//String
import { langueEnums, GalleryEnum } from "../../constants/String";

const INITIAL_DATA = {
  url: "",
  url301: "",
  title: "",
  content: "",
  shortDescription: "",
  shortContent: "",
  project: "",
  type: GalleryEnum,
  lang: "",
  link: "",
  images: [],
  documents: [],
  seo: {},
};
const Add = () => {
  const pageTitle = "Galeri";
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const [projectsDataList, setProjectsDataList] = useState([]);
  const [imageData, setImageFile] = useState(""); //Görsel Seçimi Başlar
  const [data, setData] = useState(INITIAL_DATA);
  useEffect(() => {
    setData(INITIAL_DATA)
    ProjectsListGet(JwtHelper.getCompany()._id).then((res) => {
      console.log("ProjectsListGet=>", res);
      setProjectsDataList(res);
    });
  }, []);

  const titleToURLChange = (e) => {
    //Title kısmındaki text'i URL'ye çevirir
    let _data = { ...data };
    _data.title = e;
    _data.url = e
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/Ğ/gim, "g")
      .replace(/Ü/gim, "u")
      .replace(/Ş/gim, "s")
      .replace(/I/gim, "i")
      .replace(/İ/gim, "i")
      .replace(/Ö/gim, "o")
      .replace(/Ç/gim, "c")
      .replace(/ğ/gim, "g")
      .replace(/ü/gim, "u")
      .replace(/ş/gim, "s")
      .replace(/ı/gim, "i")
      .replace(/ö/gim, "o")
      .replace(/ç/gim, "c");
    setData(_data);
  };
  const btnSave = () => {
    setIsLoading(true);
    data.images.push(imageData);
    console.log("send data=>", data);
    BlogPost(data)
      .then((res) => {
        setIsLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: `İşlem Başarılı`,
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("cevap geldi=>", err.response.data);
      });
  };

  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <ContentLoading />
      ) : (
        <div className="grid">
          <div className="col-12 lg:col-8 sm:col-12">
            <div className="card">
              <h5>Yeni {pageTitle} Ekle</h5>
              <p>
                Yeni {pageTitle} eklemek için lütfen aşağıdaki alanları
                doldurunuz.
              </p>

              <div className="col-12">
                <div className="field">
                  <label htmlFor="personnelEMail" className="d-block">
                    Proje
                  </label>
                  <Dropdown
                    value={data.project}
                    options={projectsDataList}
                    onChange={(e) =>
                      setData({
                        ...data,
                        project: e.target.value,
                      })
                    }
                    optionValue="_id"
                    optionLabel="name"
                    placeholder="Lütfen Proje Seçiniz"
                  />
                </div>
                <div className="field">
                  <label htmlFor="personnelEMail" className="d-block">
                    Dil
                  </label>
                  <Dropdown
                    value={data.lang}
                    options={langueEnums}
                    onChange={(e) =>
                      setData({
                        ...data,
                        lang: e.target.value,
                      })
                    }
                    optionValue="id"
                    optionLabel="name"
                    placeholder="Lütfen Dil Seçiniz"
                  />
                </div>
                <div className="field">
                  <label htmlFor="title" className="block">
                    Adı
                  </label>
                  <InputText
                    id="title"
                    placeholder="Lütfen girişi yapınız."
                    className="block w-100"
                    value={data.title}
                    onChange={(e) => titleToURLChange(e.target.value)}
                  />
                </div>
                <div className="field">
                  <label htmlFor="imgBox" className="block">
                    Görsel Ekle
                  </label>

                  <ImageUpload
                    setImageFile={setImageFile}
                    width={350}
                    imgData={data.images[0]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-4 sm:col-12">
            <PageSaveElement btnSave={btnSave} />
            <div className="card">
              <h5>Sayfa URL</h5>
              <div className="col-12">
                <div className="field">
                  <label htmlFor="blogURL" className="block">
                    URL
                  </label>
                  <InputText
                    id="blogURL"
                    placeholder="URL girişi"
                    className="block w-100"
                    value={data.url}
                    onChange={(e) => setData({ ...data, url: e.target.value })}
                  />
                </div>
                <div className="field">
                  <label htmlFor="blogURL" className="block">
                    301 URL
                  </label>
                  <InputText
                    id="blogURL"
                    placeholder="301 URL girişi"
                    className="block w-100"
                    value={data.url301}
                    onChange={(e) =>
                      setData({ ...data, url301: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Add, comparisonFn);
