import React, { useEffect, useState } from "react";
import Login from "./components/Login/Login";
import Main from "./pages/Main";

/*Context*/
import { MainContext } from "./context/Context";

import "./App.scss";
const App = () => {
  useEffect(() => {
    if (
      !(
        localStorage.token === "Undefined" ||
        localStorage.token === "" ||
        localStorage.token === undefined
      )
    ) {
      setIsPersonnel(true);
    }
  }, []);

  const [isPersonnel, setIsPersonnel] = useState(false);

  //Context Başlar
  const [seoDataContext, setSeoDataContext] = useState({});
  const contexData = {
    seoDataContext,
    setSeoDataContext,
  };

  if (isPersonnel) {
    return (
      <MainContext.Provider value={contexData}>
        <Main />
      </MainContext.Provider>
    );
  }

  return <Login />;
};
export default App;
