import React, { useState, useRef, useEffect } from 'react';

/*Prime Component*/
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';

/*Component*/
import ContentLoading from '../loading/ContentLoading';

/*Service*/
import {AuthoritiesGetList} from '../../service/AuthoritiesService'


const Add = () => {
    const pageTitle = "Rol"
    const [isLoading, setIsLoading] = useState(true);
    const toast = useRef(null);
    const [data, setData] = useState({
        code:'',
        authorityList: []
    });
    const [authoritiesListData, setAuthoritiesListData] = useState([]) //Yetki listesi    
    const [selectedCategories, setSelectedCategories] = useState(authoritiesListData.slice());

    const onCategoryChange = (e) => {
        let _selectedCategories = [...selectedCategories];
        if (e.checked) {
            _selectedCategories.push(e.value);
        }
        else {
            for (let i = 0; i < _selectedCategories.length; i++) {
                const selectedCategory = _selectedCategories[i];

                if (selectedCategory._id === e.value._id) {
                    _selectedCategories.splice(i, 1);
                    break;
                }
            }
        }

        setSelectedCategories(_selectedCategories);
    }
    console.log("seçilen yetki",selectedCategories)

    useEffect(() => {
        setAuthoritiesListData([])
        setIsLoading(false)
        //Yetki listesi
        AuthoritiesGetList().then(res => {
            console.log("Yetki cevap geldi Rol=>",res)
            setAuthoritiesListData(res)
            setIsLoading(false)
        });
    }, []);

    const save = () => {
        setIsLoading(true)
        data.authorityList = selectedCategories;
        // RolePost(data).then(res => {
        //     setIsLoading(false)
        //     setData({
        //         code:'',
        //         authorityList: []
        //     });
        //     console.log("cevap geldi=>",res)
        //     toast.current.show({severity:'success', summary: 'Başarılı', detail:'Başarılı Şekilde Rol Eklendi', life: 3000});
        // }).catch(err => {
        //     setIsLoading(false);
        //     console.log("cevap geldi=>", err.response.data)
        //     toast.current.show({severity: 'error', summary: 'Hata', detail: `${err.response.data}`, life: 3000});
        // })
    }
 
    return (
        <>
            {isLoading ? (
                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <ContentLoading/>
                        </div>
                    </div>
                </div>
            ):(
            <div className="grid p-fluid">
                <Toast ref={toast} />
                <div className="col">
                    <div className="card">
                        <h5>{pageTitle}</h5>
                        <p>Aşağıda ki bilgileri doldurarak {pageTitle} ekleyebilirsiniz.</p>
                        <div className="grid">
                            <div className="col-12 md:col-12">
                                <div className="field">
                                    <label htmlFor="teamName">{pageTitle} Adı</label>
                                    <InputText
                                        placeholder="Lütfen Giriş Yapınız" type="text" id="teamName" value={data.code || ''} onChange={(e) => setData({...data, code:e.target.value })} />
                                </div>
                            </div>
                                {authoritiesListData.map((category) => {
                                    return (
                                        <div key={category._id} className="col-12 md:col-4">
                                            <Checkbox inputId={category.id} name="category" value={category} onChange={onCategoryChange} checked={selectedCategories.some((item) => item.id === category.id)} />
                                            <label style={{marginLeft:7}} htmlFor={category.id}>{category.code}</label>
                                        </div>
                                    )
                                })}
                            <div className="col-12 md:col-4"></div>
                            <div className="col-12 md:col-4"></div>
                            <div className="col-12 md:col-4">
                                <Button label="Kaydet" onClick={save} className="p-button-success mr-2 mb-2" disabled={data.code ? false : true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Add, comparisonFn);
