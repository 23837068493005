import React, { useState, useEffect } from "react";

// Prime Component
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";

//Service
import { FileUploadPost } from "../../../service/FileService";

const ImageUpload = ({ setImageFile, imgData, width }) => {
  const [imageData, setImageData] = useState();

  const fileUpload = (e) => {
    FileUploadPost(e.files[0]).then((res) => {
      setImageFile(res);
      setImageData(res);
    });
  };

  useEffect(() => {
    setImageData(imgData);
  }, [imgData]);

  const btnDelete = () => {
    setImageFile("");
    setImageData("");
  };
  return (
    <div className="field">
      <Image className="d-block w-100" src={imageData} width={width} />
      {imageData ? (
        <Button
          label="Görseli Sil"
          onClick={btnDelete}
          className="d-block mb-3 p-button-danger p-button-sm d-block"
        />
      ) : (
        <FileUpload
          mode="basic"
          name="demo[]"
          chooseLabel="Görsel Seç"
          uploadHandler={fileUpload}
          customUpload
          className="d-block w-100"
        />
      )}
    </div>
  );
};

export default ImageUpload;
