import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "../AppTopbar";
import { AppFooter } from "../AppFooter";
import { AppMenu } from "../AppMenu";
import { AppConfig } from "../AppConfig";

//WEB PANEL İmport Başlar
import Dashboard from "../components/Dashboard";
import PersonnelAdd from "../components/personnel/Add";
import PersonnelList from "../components/personnel/List";
import PersonnelDetail from "../components/personnel/Detail";
import RoleAdd from "../components/role/Add";
import RoleList from "../components/role/List";
import AuthoritiesAdd from "../components/authorities/Add";
import AuthoritiesDetail from "../components/authorities/Detail";
import AuthoritiesList from "../components/authorities/List";

import CompaniesAdd from "../components/companies/Add";
import CompaniesList from "../components/companies/List";
import ProjectAdd from "../components/project/Add";
import ProjectDetail from "../components/project/Detail";
import ProjectList from "../components/project/List";

import ModuleAdd from "../components/module/Add";
import ModuleDetail from "../components/module/Detail";
import ModuleList from "../components/module/List";
import CategoryAdd from "../components/category/Add";
import CategoryDetail from "../components/category/Detail";
import CategoryList from "../components/category/List";

//Example Article
import ArticlesExampleAdd from "../components/articlesExample/Add";
import ArticlesExampleList from "../components/articlesExample/List";
import ArticlesExampleDetail from "../components/articlesExample/Detail";

//Module İmport Başlar
import BlogAdd from "../components/blog/Add";
import BlogDetail from "../components/blog/Detail";
import BlogList from "../components/blog/List";
import SSSAdd from "../components/sss/Add";
import SSSDetail from "../components/sss/Detail";
import SSSList from "../components/sss/List";

import SliderAdd from "../components/slider/Add";
import SliderDetail from "../components/slider/Detail";
import SliderList from "../components/slider/List";
import CustomerReferenceAdd from "../components/customerReference/Add";
import CustomerReferenceDetail from "../components/customerReference/Detail";
import CustomerReferenceList from "../components/customerReference/List";
import GalleryAdd from "../components/gallery/Add";
import GalleryDetail from "../components/gallery/Detail";
import GalleryList from "../components/gallery/List";
import CustomerCommentsAdd from "../components/customerComments/Add";
import CustomerCommentsDetail from "../components/customerComments/Detail";
import CustomerCommentsList from "../components/customerComments/List";
import ServicesAdd from "../components/services/Add";
import ServicesDetail from "../components/services/Detail";
import ServicesList from "../components/services/List";
import OurServeAdd from "../components/ourServe/Add";
import OurServeDetail from "../components/ourServe/Detail";
import OurServeList from "../components/ourServe/List";



import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "../assets/demo/flags/flags.css";
import "../assets/demo/Demos.scss";
import "../assets/layout/layout.scss";
import "../App.scss";
import { JwtHelper } from "../plugins/jwtHelper";
import { blogModuleEnum, developerEnum, galeriModuleEnum, hizmetlerimizModuleEnum, musteriReferansModuleEnum, musteriYorumuModuleEnum, sliderModuleEnum, sssModuleEnum } from "../constants/String";
const Main = () => {
  console.log("JWT TOKEN", JwtHelper.getUser());
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef = useRef();
  const location = useLocation();

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    {
      label: "ANASAYFA",
      role: developerEnum,
      items: [
        {
          label: "Anasayfa",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/",
        },
      ],
    },
    {
      label: "PERSONEL",
      role: developerEnum,
      items: [
        {
          label: "Yeni Personel Ekle",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/yeni-personel",
        },
        {
          label: "Personel Listesi",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/personel-listesi",
        },
      ],
    },
    {
      label: "Şirket",
      role: developerEnum,
      items: [
        {
          label: "Yeni Şirket Ekle",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/yeni-sirket",
        },
        {
          label: "Şirket Listesi",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/sirket-listesi",
        },
      ],
    },
    {
      label: "Proje",
      role: developerEnum,
      items: [
        {
          label: "Yeni Proje Ekle",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/yeni-proje",
        },
        {
          label: "Proje Listesi",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/proje-listesi",
        },
      ],
    },
    {
      label: "KATEGORİ",
      role: developerEnum,
      items: [
        {
          label: "Yeni Kategori Ekle",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/yeni-kategori-ekle",
        },
        {
          label: "Kategori Listesi",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/kategori-listesi",
        },
      ],
    },
    {
      label: "HİZMETLERİMİZ",
      role: hizmetlerimizModuleEnum,
      items: [
        {
          label: "Yeni Hizmet Ekle",
          icon: "pi pi-fw pi-home",
          role: hizmetlerimizModuleEnum,
          to: "/yeni-hizmet-ekle",
        },
        {
          label: "Hizmet Listesi",
          icon: "pi pi-fw pi-home",
          role: hizmetlerimizModuleEnum,
          to: "/hizmet-listesi",
        },
      ],
    },
    {
      label: "Hizmet verilen sektörler",
      role: hizmetlerimizModuleEnum,
      items: [
        {
          label: "Yeni Hizmet Sektörü Ekle",
          icon: "pi pi-fw pi-home",
          role: hizmetlerimizModuleEnum,
          to: "/yeni-hizmet-verilen-sektor-ekle",
        },
        {
          label: "Hizmet Sektörö Listesi",
          icon: "pi pi-fw pi-home",
          role: hizmetlerimizModuleEnum,
          to: "/hizmet-verilen-sektor-listesi",
        },
      ],
    },
    {
      label: "Slider",
      role: sliderModuleEnum,
      items: [
        {
          label: "Yeni Slider Ekle",
          icon: "pi pi-fw pi-home",
          role: sliderModuleEnum,
          to: "/yeni-slider-ekle",
        },
        {
          label: "Slider Listesi",
          icon: "pi pi-fw pi-home",
          role: sliderModuleEnum,
          to: "/slider-listesi",
        },
      ],
    },
    {
      label: "Müşteri Referans",
      role: musteriReferansModuleEnum,
      items: [
        {
          label: "Yeni Müşteri Referans Ekle",
          icon: "pi pi-fw pi-home",
          role: musteriReferansModuleEnum,
          to: "/yeni-musteri-referans-ekle",
        },
        {
          label: "Müşteri Referans Listesi",
          icon: "pi pi-fw pi-home",
          role: musteriReferansModuleEnum,
          to: "/musteri-referans-listesi",
        },
      ],
    },
    {
      label: "Galeri",
      role: galeriModuleEnum,
      items: [
        {
          label: "Yeni Galeri Ekle",
          icon: "pi pi-fw pi-home",
          role: galeriModuleEnum,
          to: "/yeni-galeri-ekle",
        },
        {
          label: "Galeri Listesi",
          icon: "pi pi-fw pi-home",
          role: galeriModuleEnum,
          to: "/galeri-listesi",
        },
      ],
    },
    {
      label: "Blog",
      // yetki: "ALL",
      role: blogModuleEnum,
      items: [
        {
          label: "Yeni Blog Ekle",
          icon: "pi pi-fw pi-home",
          // yetki: "ALL",
          role: blogModuleEnum,
          to: "/yeni-blog-ekle",
        },
        {
          label: "Blog Listesi",
          icon: "pi pi-fw pi-home",
          // yetki: "ALL",
          role: blogModuleEnum,
          to: "/blog-listesi",
        },
      ],
    },
    {
      label: "Sık Sorulan Sorular",
      role: sssModuleEnum,
      items: [
        {
          label: "Yeni Sık Sorulan Sorular Ekle",
          icon: "pi pi-fw pi-home",
          role: sssModuleEnum,
          to: "/yeni-sss-ekle",
        },
        {
          label: "Sık Sorulan Sorular Listesi",
          icon: "pi pi-fw pi-home",
          role: sssModuleEnum,
          to: "/sss-listesi",
        },
      ],
    },
    {
      label: "Müşteri Yorumu",
      role: musteriYorumuModuleEnum,
      items: [
        {
          label: "Yeni Müşteri Yorumu Ekle",
          icon: "pi pi-fw pi-home",
          role: musteriYorumuModuleEnum,
          to: "/yeni-musteri-yorumu-ekle",
        },
        {
          label: "Müşteri Yorumu Listesi",
          icon: "pi pi-fw pi-home",
          role: musteriYorumuModuleEnum,
          to: "/musteri-yorumu-listesi",
        },
      ],
    },
    {
      label: "ROL",
      role: developerEnum,
      items: [
        {
          label: "Yeni Rol Ekle",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/yeni-rol",
        },
        {
          label: "Rol Listesi",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/rol-listesi",
        },
      ],
    },
    {
      label: "Yetki",
      role: developerEnum,
      items: [
        {
          label: "Yeni Yetki Ekle",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/yeni-yetki",
        },
        {
          label: "Yetki Listesi",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/yetki-listesi",
        },
      ],
    },
    {
      label: "Modül",
      role: developerEnum,
      items: [
        {
          label: "Yeni Modül Ekle",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/yeni-modul-ekle",
        },
        {
          label: "Modül Listesi",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/modul-listesi",
        },
      ],
    },
    {
      label: "Article Example",
      role: developerEnum,
      items: [
        {
          label: "Yeni Article Ekle",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/yeni-dinamik-sayfa",
        },
        {
          label: "Article Listesi",
          icon: "pi pi-fw pi-home",
          role: developerEnum,
          to: "/dinamik-sayfa-listesi",
        },
      ],
    },
  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });
  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <AppTopbar
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu
          model={menu}
          onMenuItemClick={onMenuItemClick}
          layoutColorMode={layoutColorMode}
        />
      </div>

      <div className="layout-main-container">
        <div className="layout-main">
          <Route
            path="/"
            exact
            render={() => (
              <Dashboard colorMode={layoutColorMode} location={location} />
            )}
          />

          <Route path="/yeni-personel" component={PersonnelAdd} />
          <Route path="/personel-listesi" component={PersonnelList} />
          <Route path="/personel" component={PersonnelDetail} />
          <Route path="/yeni-rol" component={RoleAdd} />
          <Route path="/rol-listesi" component={RoleList} />
          <Route path="/yeni-yetki" component={AuthoritiesAdd} />
          <Route path="/yetki" component={AuthoritiesDetail} />
          <Route path="/yetki-listesi" component={AuthoritiesList} />
          <Route path="/yeni-sirket" component={CompaniesAdd} />
          <Route path="/sirket-listesi" component={CompaniesList} />
          <Route path="/yeni-proje" component={ProjectAdd} />
          <Route path="/proje" component={ProjectDetail} />
          <Route path="/proje-listesi" component={ProjectList} />

          <Route path="/yeni-dinamik-sayfa" component={ArticlesExampleAdd} />
          <Route
            path="/dinamik-sayfa-listesi"
            component={ArticlesExampleList}
          />
          <Route path="/dinamik-sayfa" component={ArticlesExampleDetail} />

          {/* Module Route Başlar */}
          <Route path="/yeni-blog-ekle" component={BlogAdd} />
          <Route path="/blog" component={BlogDetail} />
          <Route path="/blog-listesi" component={BlogList} />
          <Route path="/yeni-sss-ekle" component={SSSAdd} />
          <Route path="/sss" component={SSSDetail} />
          <Route path="/sss-listesi" component={SSSList} />
          <Route path="/yeni-slider-ekle" component={SliderAdd} />
          <Route path="/slider" component={SliderDetail} />
          <Route path="/slider-listesi" component={SliderList} />
          <Route
            path="/yeni-musteri-referans-ekle"
            component={CustomerReferenceAdd}
          />
          <Route path="/musteri-referans" component={CustomerReferenceDetail} />
          <Route
            path="/musteri-referans-listesi"
            component={CustomerReferenceList}
          />
          <Route path="/yeni-galeri-ekle" component={GalleryAdd} />
          <Route path="/galeri" component={GalleryDetail} />
          <Route path="/galeri-listesi" component={GalleryList} />
          <Route
            path="/yeni-musteri-yorumu-ekle"
            component={CustomerCommentsAdd}
          />
          <Route path="/musteri-yorumu" component={CustomerCommentsDetail} />
          <Route
            path="/musteri-yorumu-listesi"
            component={CustomerCommentsList}
          />
          <Route path="/yeni-modul-ekle" component={ModuleAdd} />
          <Route path="/modul" component={ModuleDetail} />
          <Route path="/modul-listesi" component={ModuleList} />
          <Route path="/yeni-kategori-ekle" component={CategoryAdd} />
          <Route path="/kategori" component={CategoryDetail} />
          <Route path="/kategori-listesi" component={CategoryList} />
          <Route path="/yeni-hizmet-ekle" component={ServicesAdd} />
          <Route path="/hizmet" component={ServicesDetail} />
          <Route path="/hizmet-listesi" component={ServicesList} />
          <Route path="/yeni-hizmet-verilen-sektor-ekle" component={OurServeAdd} />
          <Route path="/hizmet-verilen-sektor" component={OurServeDetail} />
          <Route path="/hizmet-verilen-sektor-listesi" component={OurServeList} />

          
        </div>

        <AppFooter layoutColorMode={layoutColorMode} />
      </div>

      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit
      >
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};

export default Main;
