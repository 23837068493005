import { api } from "../constants/String";
import { HTTP } from "../plugins/axios";

export function PersonnelListGet() {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/users`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}
export function PersonnelByGet(id) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/users/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}
export function PersonnelByDelete(id) {
  return new Promise((res, rej) =>
    HTTP.delete(`${api}/users/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}
export function PersonnelPost(data) {
  return new Promise((res, rej) =>
    HTTP.post(`${api}/users`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}
export function PersonnelPut(data) {
  return new Promise((res, rej) =>
    HTTP.put(`${api}/users/${data._id}`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
      })
  );
}
