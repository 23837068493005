import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

// plugins
import moment from "moment";

// Prime Component
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Toast } from "primereact/toast";

//Component
import ContentLoading from "../loading/ContentLoading";

//Service
import { RolesGetList } from "../../service/RoleService";
//String
import { momentDateFormat } from "../../constants/String";

const List = () => {
  const pageTitle = "Rol";
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    RolesGetList().then((res) => {
      console.log("data geldi", res);
      setIsLoading(false);
      setData(res);
    });
  }, []);

  //Listelemeye düzenleme ve silme butonunu eklediğimiz kısım
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Link
          className="p-button-rounded p-button-success mr-2"
          to={`/rol/${rowData.id}`}
        >
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success mr-2"
          />
        </Link>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger  mr-2"
          tooltip="Kaydı Sil"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          onClick={() => {
            console.log("rowData", rowData);
          }}
        />
      </React.Fragment>
    );
  };

  const momentBodyTemplate = (rowData) => {
    return moment(rowData.createdDate).format(momentDateFormat);
  };
  const statusBodyTemplate = (rowData) => {
    if (rowData.verify) {
      return "Onaylandı";
    }
    return "Onaylanmadı";
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };
  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      code: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      email: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue1("");
  };
  const clearFilter1 = () => {
    initFilters1();
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Aramayı Temizle"
          className="p-button-outlined"
          onClick={clearFilter1}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Genel Arama Yapınız"
          />
        </span>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>{pageTitle} Listesi</h5>
              <p>
                {pageTitle} listesinde yer alanlar aşağıda listelenmektedir.
              </p>
              <DataTable
                value={data}
                responsiveLayout="scroll"
                paginator
                rows={10}
                header={renderHeader1()}
                emptyMessage="Kayıt Bulunamadı"
                filters={filters1}
                filterDisplay="menu"
                dataKey="id"
                globalFilterFields={["code", "email"]}
                rowsPerPageOptions={[
                  10, 20, 30, 40, 50, 100, 150, 200, 250, 300,
                ]}
              >
                <Column field="code" header="Adı Soyadı"></Column>
                <Column
                  field="verify"
                  body={statusBodyTemplate}
                  header="Durum"
                ></Column>
                <Column
                  field="createdDate"
                  body={momentBodyTemplate}
                  header="Oluşturma Tarihi"
                ></Column>
                <Column
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{ minWidth: "8rem" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(List, comparisonFn);
