import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { Captcha } from "primereact/captcha";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Service*/
import { LoginUserPost } from "../../service/UserService";

const Login = () => {
  const [data, setData] = useState({
    username: localStorage.loginUserName,
    password: localStorage.loginUserPassword,
  });
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recapteToken, setRecapteToken] = useState("");
  const [checked, setChecked] = useState(); //Giriş bilgilerimi hatırla

  const showResponse = (response) => {
    //Recapte response
    setRecapteToken(response);
  };
  useEffect(() => {
    if (localStorage.loginUserInfoCheck === "true") {
      setChecked(true);
    }
  }, []);
  const login = () => {
    setIsLoading(true);
    LoginUserPost(data)
      .then((res) => {
        localStorage.setItem("token", res);
        if (checked) {
          localStorage.setItem("loginUserInfoCheck", checked);
          localStorage.setItem("loginUserName", data.username);
          localStorage.setItem("loginUserPassword", data.password);
        } else {
          localStorage.setItem("loginUserInfoCheck", "");
          localStorage.setItem("loginUserName", "");
          localStorage.setItem("loginUserPassword", "");
        }
        setIsLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Giriş Yapıldı",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const onKeyUpValue = (event) => {
    if (event.keyCode === 13) {
      login();
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
          style={{ maxWidth: "50%", margin: "15% auto" }}
        >
          <ContentLoading />
        </div>
      ) : (
        <div
          className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
          style={{ maxWidth: "50%", margin: "15% auto" }}
        >
          <Toast ref={toast} />
          <div className="text-center mb-5">
            <img
              src="assets/layout/images/logo-dark.png"
              alt="CLRSOFT"
              height="50"
              className="mb-3"
              style={{ maxWidth: "100%" }}
            />
            <div className="text-900 text-3xl font-medium mb-3">
              Giriş Ekranına Hoş Geldiniz
            </div>
            <span className="text-600 font-medium line-height-3">
              Giriş yapmak için lütfen gerekli alanları doldurunuz.
            </span>
          </div>

          <div>
            <label htmlFor="email1" className="block text-900 font-medium mb-2">
              Kullanıcı Adı
            </label>
            <InputText
              id="email1"
              type="text"
              className="w-full mb-3"
              value={data.username}
              onChange={(e) => setData({ ...data, username: e.target.value })}
            />

            <label
              htmlFor="password1"
              className="block text-900 font-medium mb-2"
            >
              Parola
            </label>
            <InputText
              onKeyUp={onKeyUpValue.bind(this)}
              id="password1"
              type="password"
              className="w-full mb-3"
              value={data.password}
              onChange={(e) => setData({ ...data, password: e.target.value })}
            />
            <Captcha
              siteKey="6LcJshshAAAAAJ8plEP5gWOM49-EfiHYkB3LVx46"
              onResponse={showResponse}
            ></Captcha>

            <div className="flex align-items-center justify-content-between mb-3 mt-3">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="rememberme1"
                  binary
                  className="mr-2"
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                />
                <label htmlFor="rememberme1">Giriş Bilgilerimi Hatırla</label>
              </div>
            </div>

            <Button
              label="Giriş Yap"
              icon="pi pi-user"
              onClick={login}
              style={{ width: "100%", marginTop: 15 }}
              className={
                "w-full" + data.username && data.password && recapteToken
                  ? " "
                  : "p-disabled"
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Login, comparisonFn);
