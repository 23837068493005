import { api } from "../constants/String";
import { HTTP } from "../plugins/axios";

export function RolesGetList() {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/roles`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function RolesPost(data) {
  return new Promise((res, rej) =>
    HTTP.post(`${api}/roles`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}
