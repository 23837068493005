import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
// plugins
import moment from "moment";

// Prime Component
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { confirmPopup } from "primereact/confirmpopup";
//Component
import ContentLoading from "../loading/ContentLoading";
//Service
import {
  PersonnelListGet,
  PersonnelByDelete,
} from "../../service/PersonnelService";
//String
import { momentDateFormat } from "../../constants/String";

const List = () => {
  const pageTitle = "Personel";
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    functionGetList();
  }, []);

  const functionGetList = () => {
    PersonnelListGet().then((res) => {
      console.log("data geldi", res);
      setIsLoading(false);
      setData(res);
    });
  };

  //Listelemeye düzenleme ve silme butonunu eklediğimiz kısım
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Link
          className="p-button-rounded p-button-success mr-2"
          to={`/personel/${rowData._id}`}
        >
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success mr-2"
          />
        </Link>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger  mr-2"
          tooltip="Kayıtı Sil"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          onClick={() => {
            confirm2(rowData._id);
          }}
        />
      </React.Fragment>
    );
  };

  const accept = (id) => {
    //Silme işlemi evet diyince
    PersonnelByDelete(id)
      .then((res) => {
        setIsLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: `Silme İşlemi Başarılı`,
          life: 3000,
        });
        functionGetList();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  const reject = () => {
    //Silme işlemi hayır diyince
  };

  //Silme Butonu Emin Misiniz Sorusu?
  const confirm2 = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Silme İşlemini Yapmak İstediğinize Emin misiniz?",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => accept(event),
      reject,
    });
  };

  const momentBodyTemplate = (rowData) => {
    return moment(rowData.createdDate).format(momentDateFormat);
  };
  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <h5>{pageTitle} Listesi</h5>
              <p>
                {pageTitle} listesinde yer alanlar aşağıda listelenmektedir.
              </p>
              <DataTable
                value={data}
                responsiveLayout="scroll"
                paginator
                rows={10}
                emptyMessage="Kayıt Bulunamadı"
                dataKey="id"
                globalFilterFields={["name", "email"]}
                rowsPerPageOptions={[
                  10, 20, 30, 40, 50, 100, 150, 200, 250, 300,
                ]}
              >
                <Column field="name" header="Adı Soyadı"></Column>
                <Column field="role.content" header="Rol"></Column>
                <Column field="username" header="Kullanıcı Adı"></Column>
                <Column
                  field="createdDate"
                  body={momentBodyTemplate}
                  header="Oluşturma Tarihi"
                ></Column>
                <Column
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{ minWidth: "8rem" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(List, comparisonFn);
