import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

/*Prime Component*/
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { JwtHelper } from "../../plugins/jwtHelper";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

//Service
import { CompaniesListGet } from "../../service/CompaniesService";
import { AuthoritiesGetList } from "../../service/AuthoritiesService";
import { RolesGetList } from "../../service/RoleService";
import { ProjectsListGet } from "../../service/ProjectsService";
import { PersonnelByGet, PersonnelPut } from "../../service/PersonnelService";
import { ModulesGetList } from "../../service/ModulesService";

/*Yetkilendirme ve Rol*/

const Detail = () => {
  const pageTitle = "Personel";
  const location = useLocation();
  const [pageUrl] = useState(location.pathname.split("/")[2]);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [data, setData] = useState({
    name: "",
    username: "",
    password: "",
    password2: "",
    role: "",
    project: [],
    authority: [],
    module: [],
  });
  console.log("data", data);
  const [roleListData, setRoleListData] = useState([]); //Rol listesi
  const [authoritiesListData, setAuthoritiesListData] = useState([]); //Yetki listesi
  const [companiesListData, setCompaniesListData] = useState([]); //Şirket listesi
  const [projectListData, setProjectListData] = useState([]); //Proje listesi
  const [modulesData, setModulesData] = useState([]); //Modül listesi
  const functionGetModulesList = () => {
    ModulesGetList().then((res) => {
      console.log("data geldi", res);
      setIsLoading(false);
      setModulesData(res);
    });
  };
  useEffect(() => {
    setIsLoading(false);
    functionGetModulesList();
    //Şirket Listesi
    CompaniesListGet().then((res) => {
      console.log("Şirket datas geldi", res);
      setCompaniesListData(res);
      //Proje Listesi
      ProjectsListGet(JwtHelper.getCompany()._id).then((res) => {
        console.log("Proje datas geldi", res);
        setProjectListData(res);
        //Rol listesi
        RolesGetList().then((res) => {
          console.log("Rol cevap geldi Rol=>", res);
          setRoleListData(res);
          //Yetki listesi
          AuthoritiesGetList()
            .then((res) => {
              console.log("Yetki cevap geldi=>", res);
              setAuthoritiesListData(res);
            })
            .then(() => {
              //Personel Detay
              PersonnelByGet(pageUrl).then((res) => {
                console.log("Personel Detay geldi", res);
                setIsLoading(false);
                setData(res);
              });
            });
        });
      });
    });
  }, [pageUrl]);

  //Dropdown Search Code Start
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.code}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.code}</div>
      </div>
    );
  };
  //Dropdown Search Code Start end

  const save = () => {
    console.log("send data", data);
    if (data.password !== data.password2) {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Şifreler Uyuşmamaktadır.",
        life: 3000,
      });
    } else {
      const yeniListe = data.module.map((item) => String(item._id));
      data.module = yeniListe;
      setIsLoading(true);
      PersonnelPut(data)
        .then((res) => {
          setIsLoading(false);
          console.log("cevap geldi=>", res);
          toast.current.show({
            severity: "success",
            summary: "Başarılı",
            detail: "İşlem Başarılı",
            life: 3000,
          });
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("cevap geldi=>", err.response.data);
          toast.current.show({
            severity: "error",
            summary: "Hata",
            detail: `${err.response.data}`,
            life: 3000,
          });
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />
          <div className="col">
            <div className="card">
              <h5>{pageTitle}</h5>
              <p>
                Aşağıda ki bilgileri doldurarak {pageTitle} ekleyebilirsiniz.
              </p>
              <div className="grid">
                <div className="col-12 md:col-6">
                  <div className="field">
                    <label htmlFor="personnelNameSurname">
                      {pageTitle} Ad Soyad
                    </label>
                    <InputText
                      placeholder="Lütfen Ad ve Soyad Giriniz"
                      type="text"
                      id="personnelNameSurname"
                      value={data.name || ""}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="personnelEMail">{pageTitle} Şifre</label>
                    <Password
                      placeholder="Lütfen Şifre Giriniz"
                      value={data.password || ""}
                      onChange={(e) =>
                        setData({ ...data, password: e.target.value })
                      }
                      toggleMask
                      promptLabel={"Parolanızı Giriniz"}
                      weakLabel={"Güçsüz"}
                      mediumLabel={"Orta"}
                      strongLabel={"Güçlü"}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="personnelEMail">
                      {pageTitle} Şifre Tekrar
                    </label>
                    <Password
                      placeholder="Lütfen Şifreyi Tekrar Giriniz"
                      value={data.password2 || ""}
                      onChange={(e) =>
                        setData({ ...data, password2: e.target.value })
                      }
                      toggleMask
                      promptLabel={"Parolanızı Giriniz"}
                      weakLabel={"Güçsüz"}
                      mediumLabel={"Orta"}
                      strongLabel={"Güçlü"}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="personnelEMail">{pageTitle} Modül</label>
                    <MultiSelect
                      value={data.module}
                      options={modulesData}
                      onChange={(e) =>
                        setData({ ...data, module: e.target.value })
                      }
                      optionLabel="name"
                      placeholder="Lütfen Modül Seçiniz"
                      filter
                      maxSelectedLabels={3}
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                    />
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="field">
                    <label htmlFor="personnelUsername">
                      {pageTitle} Kullanıcı Adı
                    </label>
                    <InputText
                      placeholder="Lütfen Kullanıcı Adı Giriniz"
                      type="text"
                      id="personnelUsername"
                      value={data.username || ""}
                      onChange={(e) =>
                        setData({ ...data, username: e.target.value })
                      }
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="personnelEMail">{pageTitle} Rol</label>
                    <Dropdown
                      value={data.role}
                      options={roleListData}
                      onChange={(e) =>
                        setData({
                          ...data,
                          role: e.target.value,
                        })
                      }
                      optionLabel="code"
                      placeholder="Lütfen Rol Seçiniz"
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="personnelEMail">{pageTitle} Yetki</label>
                    <MultiSelect
                      value={data.authority}
                      options={authoritiesListData}
                      onChange={(e) =>
                        setData({ ...data, authority: e.target.value })
                      }
                      optionLabel="code"
                      placeholder="Lütfen Yetki Seçiniz"
                      filter
                      maxSelectedLabels={3}
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="personnelGroup">{pageTitle} Şirket</label>
                    <Dropdown
                      value={data.company}
                      options={companiesListData}
                      onChange={(e) =>
                        setData({ ...data, company: e.target.value })
                      }
                      optionLabel="name"
                      placeholder="Lütfen Seçim Yapınız"
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="personnelGroup">{pageTitle} Proje</label>
                    <Dropdown
                      value={data.project}
                      options={projectListData}
                      onChange={(e) =>
                        setData({ ...data, project: e.target.value })
                      }
                      optionLabel="name"
                      placeholder="Lütfen Seçim Yapınız"
                    />
                  </div>
                </div>
                <div className="col-12 md:col-4"></div>
                <div className="col-12 md:col-4"></div>
                <div className="col-12 md:col-4">
                  <Button
                    label="Kaydet"
                    onClick={save}
                    className="p-button-success mr-2 mb-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Detail, comparisonFn);
