import { api } from "../constants/String";
import { HTTP } from "../plugins/axios";

export function AuthoritiesGetList() {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/authorities`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function AuthoritiesPost(data) {
  return new Promise((res, rej) =>
    HTTP.post(`${api}/authorities`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}
export function AuthoritiesPut(id, data) {
  return new Promise((res, rej) =>
    HTTP.put(`${api}/authorities/${id}`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function AuthoritiesDelete(id) {
  return new Promise((res, rej) =>
    HTTP.delete(`${api}/authorities/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
        //return alert(error.response.data.Errors[0].Message);
      })
  );
}

export function AuthoritiesByGet(id) {
  return new Promise((res, rej) =>
    HTTP.get(`${api}/authorities/${id}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
        //return alert(error.response.data.Errors[0].Message);
      })
  );
}
