import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

//Plugin
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { JwtHelper } from "../../plugins/jwtHelper";

//Prime Component
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";

// Component
import Seo from "../../module/seo/index";
import ContentLoading from "../../components/loading/ContentLoading";
import PageSaveElement from "../../components/subComponents/pageComponent/pageSaveElement";
import ImageUpload from "../../components/subComponents/ImageUpload/ImageUpload";

/*Service*/
import { ProjectsListGet } from "../../service/ProjectsService";
import { BlogByGet, BlogPut } from "../../service/BlogService";

//String
import { langueEnums, hizmetlerimizModuleEnum } from "../../constants/String";
const INITIAL_DATA = {
  _id: null,
  url: "",
  url301: "",
  title: "",
  content: "",
  shortDescription: "",
  shortContent: "",
  project: "",
  type: hizmetlerimizModuleEnum,
  lang: "",
  link: "",
  images: [],
  documents: [],
  seo: {},
};
const Detail = () => {
  const pageTitle = "Hizmet";
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [pageUrl] = useState(location.pathname.split("/")[2]);
  const [pageProjectId] = useState(location.pathname.split("/")[3]);
  const [imageData, setImageFile] = useState(""); //Görsel Seçimi Başlar
  const [projectsDataList, setProjectsDataList] = useState([]);
  const [ckContent, setCkContent] = useState(null);
  const toast = useRef(null);
  const [data, setData] = useState(INITIAL_DATA);
  useEffect(() => {
    ProjectsListGet(JwtHelper.getCompany()._id).then((res) => {
      console.log("ProjectsListGet=>", res);
      setProjectsDataList(res);
      BlogByGet(pageProjectId, pageUrl).then((res) => {
        setData(res);
        setIsLoading(false);
        setImageFile(res.images[0]);
      });
    });
  }, [pageProjectId, pageUrl]);
  const titleToURLChange = (e) => {
    //Title kısmındaki text'i URL'ye çevirir
    let _data = { ...data };
    _data.title = e;
    _data.url = e
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/,/gim, "-")
      .replace(/;/gim, "-")
      .replace(/:/gim, "-")
      .replace(/Ğ/gim, "g")
      .replace(/Ü/gim, "u")
      .replace(/Ş/gim, "s")
      .replace(/I/gim, "i")
      .replace(/İ/gim, "i")
      .replace(/Ö/gim, "o")
      .replace(/Ç/gim, "c")
      .replace(/ğ/gim, "g")
      .replace(/ü/gim, "u")
      .replace(/ş/gim, "s")
      .replace(/ı/gim, "i")
      .replace(/ö/gim, "o")
      .replace(/ç/gim, "c");
    setData(_data);
  };
  const btnSave = () => {
    if (data.url.includes("?")) {
      alert("URL'de geçersiz karakter mevcut");
      return "";
    }

    if (imageData === "") {
      data.images = [];
    } else {
      data.images = [imageData];
    }
    data.content = ckContent; //İçerik verelim
    setIsLoading(true);
    console.log("send Data", data);
    BlogPut(data._id, data).then((res) => {
      console.log("res", res);
      setIsLoading(false);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: `İşlem Başarılı`,
        life: 3000,
      });
    });
  };
  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <ContentLoading />
      ) : (
        <div className="grid fluid">
          <div className="col-12 lg:col-8 sm:col-12">
            <div className="card">
              <h5>{pageTitle} Detay</h5>
              <p>{pageTitle} detayını aşağıda görebilirsiniz.</p>

              <div className="col-12">
                {projectsDataList.length > 1 && (
                  <div className="field">
                    <label htmlFor="personnelEMail" className="d-block">
                      Proje
                    </label>
                    <Dropdown
                      value={data.project}
                      options={projectsDataList}
                      className="w-100"
                      onChange={(e) =>
                        setData({
                          ...data,
                          project: e.target.value,
                        })
                      }
                      optionValue="_id"
                      optionLabel="name"
                      placeholder="Lütfen Proje Seçiniz"
                    />
                  </div>
                )}
                <div className="field">
                  <label htmlFor="personnelEMail" className="d-block">
                    Dil
                  </label>
                  <Dropdown
                    value={data.lang}
                    options={langueEnums}
                    className="w-100"
                    onChange={(e) =>
                      setData({
                        ...data,
                        lang: e.target.value,
                      })
                    }
                    optionValue="id"
                    optionLabel="name"
                    placeholder="Lütfen Dil Seçiniz"
                  />
                </div>

                <div className="field">
                  <label htmlFor="title" className="block">
                    Adı
                  </label>
                  <InputText
                    id="title"
                    placeholder="Lütfen girişi yapınız."
                    className="block w-100"
                    value={data.title}
                    onChange={(e) => titleToURLChange(e.target.value)}
                  />
                </div>
                <div className="field">
                  <label htmlFor="shortDescription" className="block">
                    Kısa Açıklama
                  </label>
                  <InputText
                    id="shortDescription"
                    placeholder="Lütfen girişi yapınız."
                    className="block w-100"
                    value={data.shortDescription}
                    onChange={(e) =>
                      setData({ ...data, shortDescription: e.target.value })
                    }
                  />
                </div>
                <div className="field">
                  <label htmlFor="content" className="block">
                    İçerik
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={data.content || ""}
                    onChange={(_, editor) => {
                      const dataCk = editor.getData();
                      setCkContent(dataCk);
                    }}
                  />
                </div>
                <div className="field">
                  <label htmlFor="imgBox" className="block">
                    Görsel Ekle
                  </label>

                  <ImageUpload
                    setImageFile={setImageFile}
                    width={350}
                    imgData={data.images[0]}
                  />
                </div>
              </div>
            </div>
            <Seo blogData={data.seo ? data.seo : {}} />
          </div>
          <div className="col-12 lg:col-4 sm:col-12">
            <PageSaveElement btnSave={btnSave} />
            <div className="card">
              <h5>Sayfa URL</h5>
              <div className="col-12">
                <div className="field">
                  <label htmlFor="blogURL" className="block">
                    URL
                  </label>
                  <InputText
                    id="blogURL"
                    placeholder="URL girişi"
                    className="block w-100"
                    value={data.url}
                    onChange={(e) => setData({ ...data, url: e.target.value })}
                  />
                </div>
                <div className="field">
                  <label htmlFor="blogURL" className="block">
                    301 URL
                  </label>
                  <InputText
                    id="blogURL"
                    placeholder="301 URL girişi"
                    className="block w-100"
                    value={data.url301}
                    onChange={(e) =>
                      setData({ ...data, url301: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Detail, comparisonFn);
