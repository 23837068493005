//Panel Login Başlar
import { HTTP } from "../plugins/axios";

export function LoginUserPost(data) {
  return new Promise((res, rej) =>
    // HTTP.post(`https://panel.api.clrsoft.com.tr/login`, data)
    HTTP.post(`https://panelapi.clrsoft.com.tr/login`, data)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("error=>", error);
        rej(error);
        //return alert(error.response.data.Errors[0].Message);
      })
  );
}